var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about__container"
  }, [_c('div', {
    staticClass: "about__lnb"
  }, [_c('ul', [_c('li', {
    staticClass: "active"
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function01');
      }
    }
  }, [_vm._v(" 디자인 관리 ")])]), _c('li', [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function02');
      }
    }
  }, [_vm._v(" 반응형 지원 ")])]), _c('li', [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function03');
      }
    }
  }, [_vm._v(" 솔루션 연결 ")])])])]), _vm._m(0), _c('div', {
    staticClass: "about__function"
  }, [_c('article', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_about_design01.png"),
      "alt": ""
    }
  }), _vm._m(1), _c('button', {
    staticClass: "lnk__more ga_f_20",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/theme-list?id=3');
      }
    }
  }, [_c('span', [_vm._v("자세히 보기")])])]), _c('article', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_about_design02.png"),
      "alt": ""
    }
  }), _vm._m(2), _c('button', {
    staticClass: "lnk__more ga_f_21",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/component-list');
      }
    }
  }, [_c('span', [_vm._v("자세히 보기")])])]), _vm._m(3), _vm._m(4)]), _c('div', {
    staticClass: "about__other"
  }, [_c('article', [_vm._m(5), _c('ul', [_c('li', {
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function02');
      }
    }
  }, [_vm._m(6)]), _c('li', {
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/introduction/function03');
      }
    }
  }, [_vm._m(7)])])])]), _c('section', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.getLoginStatus,
      expression: "!getLoginStatus"
    }],
    staticClass: "about_start about_start--design"
  }, [_c('article', [_vm._m(8), _c('button', {
    staticClass: "lnk__start--dark ga_f_22",
    on: {
      "click": function click($event) {
        return _vm.$emit('movePage', '/accounts/signup');
      }
    }
  }, [_vm._v(" 무료 시작하기 ")])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about__visual about__visual--design"
  }, [_c('dl', [_c('dt', [_vm._v("디자인 관리")]), _c('dd', [_vm._v(" 트렌디한 테마와 컴포넌트 디자인으로"), _c('br'), _vm._v(" 내 쇼핑몰 디자인을 완성하세요. ")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', [_c('dt', [_vm._v("전문 디자이너가 만든"), _c('br'), _vm._v("트렌디한 테마")]), _c('dd', [_vm._v(" 무료 테마라고해서 무료스러운 테마를 제공하지 않습니다. 큐픽 테마는 전문 디자이너에 의해 제작된 테마만 제공합니다. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', [_c('dt', [_vm._v("컴포넌트 조립으로"), _c('br'), _vm._v("자유로운 배치")]), _c('dd', [_vm._v(" 독립적으로 사용할 수 있는 컴포넌트 시스템으로 내가 원하는 디자인 컴포넌트를 페이지 어디든 자유롭게 배치하고 스타일을 편집할 수 있습니다. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('article', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_about_design03.png"),
      "alt": ""
    }
  }), _c('dl', [_c('dt', [_vm._v("누구나 간편한"), _c('br'), _vm._v("디자인 옵션 설정")]), _c('dd', [_vm._v(" 직관적이고 누구나 사용하기 쉽게 구성된 다양한 디자인 옵션 설정을 제공하며 실시간으로 편집된 화면을 볼 수 있습니다. ")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('article', [_c('img', {
    attrs: {
      "src": require("@/assets/images/intro/img_about_design04.png"),
      "alt": ""
    }
  }), _c('dl', [_c('dt', [_vm._v("깨짐없이 깔끔하고"), _c('br'), _vm._v("가벼운 디자인")]), _c('dd', [_vm._v(" 테마 디자인은 비트맵 이미지 사용을 최소화하고 모든 디바이스 및 해상도에서 최적화된 SVG 코드로 적용되어 빠르고 깨짐 없는 디자인을 제공합니다. ")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('span', [_vm._v("다른 내용이")]), _vm._v(" 더 궁금하신가요?")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', {
    staticClass: "about__other--responsive"
  }, [_c('dt', [_vm._v("반응형 지원")]), _c('dd', [_vm._v(" PC, 모바일을 유연하게 대응하는"), _c('br'), _vm._v("반응형 쇼핑몰을 쉽게 제작해보세요. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dl', {
    staticClass: "about__other--solution"
  }, [_c('dt', [_vm._v("솔루션 연결")]), _c('dd', [_vm._v(" 비즈니스에 맞는 솔루션과 연동하여"), _c('br'), _vm._v("바로 쇼핑몰 운영을 시작하세요. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('span', [_vm._v("다양한 디자인으로")]), _vm._v(" 쇼핑몰에 최적화된"), _c('br'), _vm._v("큐픽으로 시작하세요. ")]);
}]

export { render, staticRenderFns }