<template>
  <div class="about__container">
    <div class="about__lnb">
      <ul>
        <li class="active">
          <button
            type="button"
            @click="$emit('movePage', '/introduction/function01')"
          >
            디자인 관리
          </button>
        </li>
        <li>
          <button
            type="button"
            @click="$emit('movePage', '/introduction/function02')"
          >
            반응형 지원
          </button>
        </li>
        <li>
          <button
            type="button"
            @click="$emit('movePage', '/introduction/function03')"
          >
            솔루션 연결
          </button>
        </li>
      </ul>
    </div>
    <div class="about__visual about__visual--design">
      <dl>
        <dt>디자인 관리</dt>
        <dd>
          트렌디한 테마와 컴포넌트 디자인으로<br />
          내 쇼핑몰 디자인을 완성하세요.
        </dd>
      </dl>
    </div>
    <div class="about__function">
      <article>
        <img src="~@/assets/images/intro/img_about_design01.png" alt="" />
        <dl>
          <dt>전문 디자이너가 만든<br />트렌디한 테마</dt>
          <dd>
            무료 테마라고해서 무료스러운 테마를 제공하지 않습니다. 큐픽 테마는
            전문 디자이너에 의해 제작된 테마만 제공합니다.
          </dd>
        </dl>
        <button
          type="button"
          class="lnk__more ga_f_20"
          @click="$emit('movePage', '/introduction/theme-list?id=3')"
        >
          <span>자세히 보기</span>
        </button>
      </article>
      <article>
        <img src="~@/assets/images/intro/img_about_design02.png" alt="" />
        <dl>
          <dt>컴포넌트 조립으로<br />자유로운 배치</dt>
          <dd>
            독립적으로 사용할 수 있는 컴포넌트 시스템으로 내가 원하는 디자인
            컴포넌트를 페이지 어디든 자유롭게 배치하고 스타일을 편집할 수
            있습니다.
          </dd>
        </dl>
        <button
          type="button"
          class="lnk__more ga_f_21"
          @click="$emit('movePage', '/introduction/component-list')"
        >
          <span>자세히 보기</span>
        </button>
      </article>
      <article>
        <img src="~@/assets/images/intro/img_about_design03.png" alt="" />
        <dl>
          <dt>누구나 간편한<br />디자인 옵션 설정</dt>
          <dd>
            직관적이고 누구나 사용하기 쉽게 구성된 다양한 디자인 옵션 설정을
            제공하며 실시간으로 편집된 화면을 볼 수 있습니다.
          </dd>
        </dl>
      </article>
      <article>
        <img src="~@/assets/images/intro/img_about_design04.png" alt="" />
        <dl>
          <dt>깨짐없이 깔끔하고<br />가벼운 디자인</dt>
          <dd>
            테마 디자인은 비트맵 이미지 사용을 최소화하고 모든 디바이스 및
            해상도에서 최적화된 SVG 코드로 적용되어 빠르고 깨짐 없는 디자인을
            제공합니다.
          </dd>
        </dl>
      </article>
    </div>
    <div class="about__other">
      <article>
        <p><span>다른 내용이</span> 더 궁금하신가요?</p>
        <ul>
          <li @click="$emit('movePage', '/introduction/function02')">
            <dl class="about__other--responsive">
              <dt>반응형 지원</dt>
              <dd>
                PC, 모바일을 유연하게 대응하는<br />반응형 쇼핑몰을 쉽게
                제작해보세요.
              </dd>
            </dl>
          </li>
          <li @click="$emit('movePage', '/introduction/function03')">
            <dl class="about__other--solution">
              <dt>솔루션 연결</dt>
              <dd>
                비즈니스에 맞는 솔루션과 연동하여<br />바로 쇼핑몰 운영을
                시작하세요.
              </dd>
            </dl>
          </li>
        </ul>
      </article>
    </div>
    <section class="about_start about_start--design" v-show="!getLoginStatus">
      <article>
        <p>
          <span>다양한 디자인으로</span> 쇼핑몰에 최적화된<br />큐픽으로
          시작하세요.
        </p>
        <button
          class="lnk__start--dark ga_f_22"
          @click="$emit('movePage', '/accounts/signup')"
        >
          무료 시작하기
        </button>
      </article>
    </section>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import "@/assets/scss/intro.scss";

const ModuleAuth = createNamespacedHelpers("ModuleAuth");

export default {
  data() {
    return {};
  },
  computed: {
    ...ModuleAuth.mapGetters(["getLoginStatus"])
  },
  methods: {},
  mounted() {},
  beforeDestroy() {}
};
</script>

<style lang="scss" scoped>
div,
button {
  font-family: "Pretendard", "Malgun Gothic", sans-serif;
  color: #1c1e23;
  line-height: 1.25;
}
</style>
